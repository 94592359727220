<template>
    <div id="page-tv-devices-local-details">
        <app-loader v-if="loading.customer" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- 
                        @todo
                        Реализовать механизм, который позволяет возвращаться на предыдущую страницу с учетом пагинации для удобства пользователя
                    -->

                    <router-link class="btn btn-back-to" :to="{ name: 'devices-local' }">Back to TV Devices</router-link>

                    <div class="header-info">
                        <h1 class="name">{{ fullname }}</h1>
                        <div class="status">Account status: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-tab-12">
                    <app-packages-list :packages="packages" :loading="loading.packages" />
                </div>

                <div class="col-6 col-tab-12">
                    <app-devices-list :devices="devices" :loading="loading.devices" />
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-tab-12">
                    <app-table-details
                        :rows="rows.summary"
                        :data="formatted_summary"

                        :loading="loading.summary"

                        title="TV Devices Kaltura Details"
                    />
                </div>

                <div class="col-6 col-tab-12">
                    <app-customer-event-log :customer_uuid="customer_uuid" @refresh-comments-list="refreshCommentsList" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-comments :customer_uuid="customer_uuid" ref="comments" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'
import appCustomerEventLog from '@/components/app-customer-event-log'
import appComments from '@/components/app-comments'

import appPackagesList from '../../../components/app-packages-list'
import appDevicesList from '../../../components/app-devices-list'

export default {
    components: {
        appLoader,
        appPackagesList,
        appDevicesList,
        appTableDetails,
        appCustomerEventLog,
        appComments,
    },

    data() {
        return {
            loading: {
                customer: false,
                packages: false,
                summary: false,
                devices: false,
            },

            customer: {},
            packages: [],
            summary: {},
            devices: [],

            rows: {
                summary: [
                    { key: 'UUID',               title: 'HouseHoldUUID'                 },
                    { key: 'HouseholdID',        title: 'Kaltura Household ID'          },
                    { key: 'MasterUserID',       title: 'Kaltura Master User ID'        },
                    { key: 'DefaultUserID',      title: 'Kaltura Default User ID'       },
                    { key: 'OTTUsername',        title: 'Kaltura OTT Username'          },
                    { key: 'ParentalPIN',        title: 'Kaltura Household PIN'         },
                    { key: 'ParentalPINEnabled', title: 'Kaltura Household PIN Enabled' },
                ],
            },
        }
    },

    computed: {
        customer_uuid() {
            return this.$route.params.uuid
        },

        fullname() {
            const fullname = [this.customer.FirstName, this.customer.LastName].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        status() {
            return this.loading.customer ? {
                text: 'Loading',
                value: 'info',
            } : (
                this.customer.Active ? {
                    text: 'Active',
                    value: 'success',
                } : {
                    text: 'Inactive',
                    value: 'danger',
                }
            )
        },

        formatted_summary() {
            return {
                UUID: this.summary.UUID ? this.summary.UUID : '',
                HouseholdID: this.summary.HouseholdID ? this.summary.HouseholdID : '',
                MasterUserID: this.summary.MasterUserID ? this.summary.MasterUserID : '',
                DefaultUserID: this.summary.DefaultUserID ? this.summary.DefaultUserID : '',
                OTTUsername: this.summary.OTTUsername ? this.summary.OTTUsername : '',
                ParentalPIN: this.summary.ParentalPIN ? this.summary.ParentalPIN : '',
                ParentalPINEnabled: typeof this.summary.ParentalPINEnabled == 'boolean' ? this.summary.ParentalPINEnabled ? 'true' : 'false' : '',
            }
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getCustomerDetails()

            this.getCustomerPackages()

            this.getCustomerHouseholds()

            this.getCustomerDevices()
        },

        getCustomerDetails() {
            this.loading.customer = true

            this.$store.dispatch('getCustomerByUUID', { uuid: this.customer_uuid })
                .then(customer => {
                    this.customer = customer
                    this.loading.customer = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading.customer = false
                    this.$router.push({ name: 'devices-local', replace: true })
                })
        },

        getCustomerPackages() {
            this.loading.packages = true

            this.$store.dispatch('getCustomerPackages', { uuid: this.customer_uuid })
                .then(({ Packages }) => {

                    console.log(JSON.parse(JSON.stringify(Packages)))

                    this.packages = Packages

                    this.loading.packages = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading.packages = false
                })
        },

        getCustomerHouseholds() {
            this.loading.summary = true

            this.summary = {}

            this.$store.dispatch('getCustomerHouseholds', { uuid: this.customer_uuid })
                .then(response => {
                    if (Array.isArray(response) && response.length) {
                        this.summary = response[0]

                        this.loading.summary = false
                    } else {
                        this.loading.summary = false
                    }
                })
                .catch(error => {
                    console.log(error)

                    this.loading.summary = false

                    this.closeSidebar()
                })
        },

        getCustomerDevices() {
            this.loading.devices = true

            this.$store.dispatch('getCustomerDevices', { uuid: this.customer_uuid })
                .then(devices => {
                    console.log(devices)

                    this.devices = devices

                    this.loading.devices = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading.devices = false
                })
        },

        refreshCommentsList() {
            this.$refs.comments.search()
        },
    },
}
</script>

<style lang="scss">
#page-tv-devices-local-details {
    margin: 24px 0 80px;

    .row {
        .col-6 {
            display: flex;
        }
    }

    .app-packages-list {
        height: fit-content;
    }

    .app-package {
        .main-info,
        .description {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .description {
            margin: 16px 0 0;
        }
    }

    .app-customer-event-log {
        .app-textarea {
            flex-grow: 1;
        }
    }

    .row {
        &:nth-child(2) {
            .col-6 {
                margin-bottom: 30px;
            }
        }

        &:nth-child(3) {
            .col-6 {
                margin-bottom: 30px;
            }
        }
    }

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 48px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-left: 32px;

            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-default);

            .label {
                margin-left: 16px;
                padding: 4px 12px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;

                /* @todo Использовать @each и map-get */

                &.success {
                    background-color: var(--color-success-bg);
                    color: var(--color-success);
                }

                &.info {
                    background-color: var(--color-info-bg);
                    color: var(--color-info);
                }

                &.warning {
                    background-color: var(--color-warning-bg);
                    color: var(--color-warning);
                }

                &.danger {
                    background-color: var(--color-danger-bg);
                    color: var(--color-danger);
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-tv-devices-local-details {
        .header-info {
            flex-wrap: wrap;

            .name {
                width: 100%;

                font-size: 48px;
                line-height: 56px;
            }

            .status {
                margin: 12px 0 0 0;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-tv-devices-local-details {
        .row {
            &:nth-child(2) {
                .col-6 {
                    margin-bottom: 15px;
                }
            }

            &:nth-child(3) {
                .col-6 {
                    margin-bottom: 15px;
                }
            }
        }

        .app-comments-wrap {
            .app-pagination {
                margin-top: 15px;
            }
        }

        .btn-back-to {
            margin-bottom: 8px;
        }

        .header-info {
            margin-bottom: 30px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }

            .status {
                font-size: 14px;

                .label {
                    margin-left: 12px;
                    padding: 2px 8px;

                    font-size: 12px;
                    line-height: 20px;

                    text-transform: uppercase;
                }
            }

        }
    }
}
</style>