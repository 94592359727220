<template>
    <div class="app-devices-list">
        <app-loader v-if="loading"></app-loader>

        <h2 class="heading">{{ title }}</h2>

        <div class="devices-list">
            <app-device
                v-for="device in devices"

                :key="device.UUID"

                :device="device"
            />
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appDevice from './components/app-device'

export default {
    components: {
        appLoader,
        appDevice,
    },

    props: {
        title:   { type: String,  default: 'TV Devices' },
        devices: { type: Array,   required: true        },
        loading: { type: Boolean, default: false        },
    },
}
</script>

<style lang="scss">
.app-devices-list {
    position: relative;

    width: 100%;

    padding: 24px 24px 8px;

    background-color: var(--color-component-bg-primary);
    border-radius: $border-radius-primary;
    box-shadow: var(--box-shadow-primary);

    .devices-list {
        margin-top: 8px;

        .app-device {
            box-shadow: 0 1px 0 var(--color-divider);

            &:last-child {
                box-shadow: 0 1px 0 transparent;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-devices-list {
        padding: 24px 8px 0;

        h2 {
            padding-left: 8px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-devices-list {
        padding: 16px 8px 0;

        h2 {
            padding-left: 0;
        }
    }
}
</style>