<template>
    <div class="app-device">
        <div class="main-info">
            <img src="./img/device.jpg">

            <h3 class="heading">{{ title }}</h3>
        </div>

        <div class="table">
            <div class="cell">
                <div class="key">Last Seen:</div>
                <div class="value">{{ last_seen }}</div>
            </div>

            <div class="cell">
                <div class="key">Firmware:</div>
                <div class="value">{{ firmware }}</div>
            </div>

            <div class="cell">
                <div class="key">First activated:</div>
                <div class="value">{{ first_activated }}</div>
            </div>

            <div class="cell">
                <div class="key">Last IP:</div>
                <div class="value">{{ last_ip }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import formatDateUnix from '@/helpers/format-date-unix'

export default {
    props: {
        device: { type: Object, required: true },
    },

    computed: {
        title() {
            let title = ''

            if (this.device.SerialNumber == this.device.Name) {
                title = `SN: ${ this.device.SerialNumber }`
            } else {
                title = `${this.device.Name} (SN: ${ this.device.SerialNumber })`
            }

            return title
        },

        last_seen() {
            return this.device.LastAccessDateUnix ? formatDateUnix(this.device.LastAccessDateUnix) : 'never'
        },

        firmware() {
            return this.device.VersionNumber ? this.device.VersionNumber : 'unknown'
        },

        first_activated() {
            return this.device.ActivatedOn ? formatDateUnix(this.device.ActivatedOn) : 'never'
        },

        last_ip() {
            return this.device.LastAccessIP ? this.device.LastAccessIP.split(',')[0] : 'unknown'
        },
    },
}
</script>

<style lang="scss">
.app-device {
    padding: 16px 0;

    font-size: 16px;
    line-height: 24px;

    box-shadow: 0 1px 0 transparent;

    .main-info {
        display: flex;
        align-items: center;

        margin-bottom: 24px;

        img {
            display: block;
            width: 56px;
            height: 56px;
            margin-right: 24px;
            object-fit: contain;
            border-radius: $border-radius-primary;
        }

        h3 {
            @include text-overflow();
        }
    }

    .table {
        display: flex;
        flex-wrap: wrap;

        border: 1px solid #ebebeb;
        border-radius: $border-radius-primary;

        .cell {
            flex: 0 0 50%;
            max-width: 50%;

            padding: 12px 24px;

            box-shadow: 0 1px 0 #ebebeb;

            &:nth-child(2n+1) {
                border-right: 1px solid #ebebeb;
            }

            &:nth-last-child(-n+2) {
                box-shadow: 0 1px 0 transparent;
            }

            .value {
                color: #999; /* @todo Создать отдельную переменную */
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-device {
        .main-info {
            margin-bottom: 20px;
        }

        .table {
            .cell {
                padding: 12px 16px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-device {
        padding: 8px 0;

        .main-info {
            margin-bottom: 8px;

            img {
                margin-right: 16px;
            }

            h3 {
                font-size: 18px;
            }
        }

        .table {
            .cell {
                font-size: 14px;
            }
        }
    }
}
</style>